import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";

// Components
import { LinkMainInt } from "./links/LinkMain";
import SVGLogo from "../Images/Logos/SVGLogo";
import TitleSmall from "./titles/TitleSmall";
import { colors, mediaQueries } from "../../configs/_configSite";

const WrapperLogo = styled(LinkMainInt)`
  z-index: 1;
  position: relative;
  width: auto;
  text-decoration: none;
  text-align: center;

  &:hover > * {
    color: ${colors.white};
  }
  &:hover > svg > path {
    fill: ${colors.white};
  }

  @media (max-width: ${mediaQueries.xxs}px) {
    grid-column-gap: 0px;
    width: auto;
    display: block;
  }
`;

export default () => (
  <WrapperLogo className="logo-main">
    <SVGLogo
      cssProp={`
        @media (max-width: ${mediaQueries.xxs}px) {
          width: 27px;
          height: 40px;
        }
        path { transition: 0.2s };
    `}
    />

    <TitleSmall
      css={css`
        display: inline-block;
        bottom: 10px;
        color: ${colors.gold};
        font-size: 1.5rem;
        text-transform: none;
        transition: 0.2s;
        width: fit-content;
        @media (max-width: ${mediaQueries.xxs}px) {
          font-size: 1.25rem;
          display: none;
        }
      `}
    >
      Anana Vestige
    </TitleSmall>
  </WrapperLogo>
);
