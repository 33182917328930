import React from "react";
// import { Link } from "gatsby";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import TransitionLink from "gatsby-plugin-transition-link";
import { PropTypes } from "prop-types";
import _configBase from "../../../configs/_configBase";
import { colors, fonts } from "../../../configs/_configSite";
import { exitAnimation } from "../../../utilities/animations";

const styles = `
  ${_configBase};
  color: ${colors.gold};
  font-family: ${fonts.secondary};
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.2s;
  &:hover {
    color: ${colors.white};
  }
`;

const LinkMainIntAnimated = ({ children, to, css: cssProp, className }) => {
  return (
    <TransitionLink
      className={className}
      to={to}
      css={css`
        ${styles}
        ${cssProp}
      `}
      exit={{
        delay: 0,
        length: 0.5,
        trigger: () => {
          exitAnimation();
        }
      }}
      entry={{
        delay: 0.5,
        length: 1
      }}
    >
      {children}
    </TransitionLink>
  );
};

LinkMainIntAnimated.defaultProps = {
  to: "/",
  className: "",
  css: "",
  children: <></>
};
LinkMainIntAnimated.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
  css: PropTypes.string,
  children: PropTypes.node
};

export const LinkMainInt = LinkMainIntAnimated;

export const LinkMainExt = styled.a`
  ${styles}
`;
