import React, { useState } from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";

// Components
import { LinkMainInt } from "../Base/links/LinkMain";
import Socialicons from "../Base/socials/SocialIcons";
// import ButtonOpenCart from "../cart/ButtonOpenCart";
import Logo from "../Base/Logo";

// Config
import { colors, socials, mediaQueries } from "../../configs/_configSite";

const LogoWrapper = styled.div`
  position: absolute;
  z-index: 100;
  top: 15px;
  left: 30px;
  @media (max-width: ${mediaQueries.xxs}px) {
    top: 12px;
    left: 20px;
  }
`;

const LinksWrapper = styled.div`
  position: absolute;
  z-index: 100;
  top: 15px;
  right: 60px;
  transition: 0.25s;
  opacity: 1;
`;

const ActionsWrapper = styled.div`
  position: absolute;
  z-index: 100;
  top: 24px;
  right: 30px;
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  grid-row-gap: 20px;
  text-align: center;
  justify-items: center;
  transition: 0.25s;

  @media (max-width: ${mediaQueries.md}px) {
    top: 55px;
    opacity: 1;
  }
`;

const Toggle = styled.button`
  background: none;
  outline: none;
  border: none;
  color: ${colors.gold};

  font-size: 2rem;
  line-height: 0.6em;
  padding: 0;
  margin: -4px 0 0 2px;
  transition: 0.25s;
  position: absolute;
  top: 20px;
  right: 30px;
  z-index: 100;

  :before {
    content: "\\d7";
    display: block;
  }
  @media (min-width: ${mediaQueries.md}px) {
    display: none;
  }
`;

export default () => {
  const [showLinks, setShowLinks] = useState(false);
  return (
    <>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>

      <LinksWrapper
        css={css`
          @media (max-width: ${mediaQueries.md}px) {
            ${!showLinks && "right: -50px; opacity: 0;"}
          }
        `}
      >
        <LinkMainInt
          css={css`
            display: inline-table;
            margin-top: 5px;
            margin-right: 20px;
          `}
          to="/journal"
        >
          Journal
        </LinkMainInt>
        <LinkMainInt
          css={css`
            display: inline-table;
            margin-top: 5px;
            margin-right: 20px;
          `}
          to="/contact"
        >
          Contact
        </LinkMainInt>
      </LinksWrapper>

      <Toggle
        type="button"
        onClick={() => setShowLinks(!showLinks)}
        css={css`
          ${!showLinks && "transform: rotate(45deg);"}
        `}
      />
      <ActionsWrapper
        css={css`
          @media (max-width: ${mediaQueries.md}px) {
            ${!showLinks && "right: -50px; opacity: 0;"}
          }
        `}
      >
        <Socialicons
          href={socials.facebook}
          color={colors.gold}
          colorHover={colors.white}
          social="facebook"
        />
        <Socialicons
          href={socials.instagram}
          color={colors.gold}
          colorHover={colors.white}
          social="instagram"
        />
      </ActionsWrapper>
    </>
  );
};
