/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

const IconContainer = styled.a`
  z-index: 1;
  position: relative;
  width: 20px;
  height: 20px;
  line-height: 0;
  transition: 0.2s;

  &:hover {
    background: transparent;
  }

  path {
    transition: 0.2s;
  }
`;

const SocialIcons = ({ social, href, color, colorHover, cssProp }) => (
  <IconContainer
    href={href}
    css={css`
      position: relative;
      ${cssProp}
      &:hover {
        border-color: ${colorHover};
        path {
          fill: ${colorHover};
        }
      }
    `}
  >
    {social === "instagram" && (
      <svg
        width="15"
        height="15"
        viewBox="0 0 54 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.006 13.1551C19.3442 13.1551 13.1642 19.3365 13.1642 27C13.1642 34.6635 19.3442 40.8449 27.006 40.8449C34.6678 40.8449 40.8479 34.6635 40.8479 27C40.8479 19.3365 34.6678 13.1551 27.006 13.1551ZM27.006 36.001C22.0548 36.001 18.007 31.9644 18.007 27C18.007 22.0356 22.0427 17.999 27.006 17.999C31.9693 17.999 36.005 22.0356 36.005 27C36.005 31.9644 31.9573 36.001 27.006 36.001ZM44.6426 12.5888C44.6426 14.3841 43.197 15.818 41.4141 15.818C39.6191 15.818 38.1855 14.3721 38.1855 12.5888C38.1855 10.8054 39.6311 9.35948 41.4141 9.35948C43.197 9.35948 44.6426 10.8054 44.6426 12.5888ZM53.8103 15.8662C53.6055 11.5404 52.6176 7.70869 49.4493 4.55171C46.293 1.39473 42.4621 0.406672 38.1373 0.18978C33.68 -0.0632601 20.32 -0.0632601 15.8627 0.18978C11.5499 0.394622 7.71902 1.38268 4.5507 4.53966C1.38238 7.69664 0.406581 11.5284 0.189738 15.8542C-0.063246 20.3125 -0.063246 33.6754 0.189738 38.1338C0.394534 42.4596 1.38238 46.2913 4.5507 49.4483C7.71902 52.6053 11.5379 53.5933 15.8627 53.8102C20.32 54.0633 33.68 54.0633 38.1373 53.8102C42.4621 53.6054 46.293 52.6173 49.4493 49.4483C52.6056 46.2913 53.5934 42.4596 53.8103 38.1338C54.0632 33.6754 54.0632 20.3246 53.8103 15.8662ZM48.0519 42.9174C47.1122 45.2791 45.2931 47.0986 42.9199 48.0505C39.3661 49.4603 30.9333 49.135 27.006 49.135C23.0788 49.135 14.6339 49.4483 11.0921 48.0505C8.73095 47.1107 6.91188 45.2912 5.96018 42.9174C4.5507 39.3628 4.87596 30.9281 4.87596 27C4.87596 23.0719 4.56274 14.6251 5.96018 11.0826C6.89983 8.72085 8.71891 6.90137 11.0921 5.94946C14.646 4.53966 23.0788 4.865 27.006 4.865C30.9333 4.865 39.3781 4.55171 42.9199 5.94946C45.2811 6.88932 47.1002 8.7088 48.0519 11.0826C49.4613 14.6372 49.1361 23.0719 49.1361 27C49.1361 30.9281 49.4613 39.3749 48.0519 42.9174Z"
          fill={color}
        />
      </svg>
    )}
    {social === "facebook" && (
      <svg
        width="10"
        height="17"
        viewBox="0 0 12 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.48636 20.0032V11.0565H0V7.50121H3.48636V4.69998C3.48636 1.65652 5.65 0 8.80909 0C10.3227 0 11.6227 0.097672 12 0.140648V3.32085H9.80909C8.09091 3.32085 7.75909 4.02409 7.75909 5.0516V7.50121H11.6364L11.1045 11.0565H7.75909V20.0032"
          fill={color}
        />
      </svg>
    )}
  </IconContainer>
);

SocialIcons.defaultProps = {
  social: "instagram",
  href: "/",
  color: "#FFFFFF",
  colorHover: "#000000",
  cssProp: ""
};

SocialIcons.propTypes = {
  social: PropTypes.string,
  href: PropTypes.string,
  color: PropTypes.string,
  colorHover: PropTypes.string,
  cssProp: PropTypes.string
};

export default SocialIcons;
