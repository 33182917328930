/* eslint-disable no-unused-expressions */
import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Global, css } from "@emotion/core";
import Div100vh from "react-div-100vh";
import BlackChancery from "../../../static/fonts/blackchancery.ttf";
import { mediaQueries } from "../../configs/_configSite";
import { ContextUI } from "../../contexts/context-ui";
import NavbarMain from "../Navigation/NavbarMain";
import { enterAnimation } from "../../utilities/animations";

const Layout = ({ children, scrollLock }) => {
  const { setMobile, appLoaded, setAppLoaded } = useContext(ContextUI);
  const toggleMobile = () => {
    window.innerWidth > mediaQueries.md ? setMobile(false) : setMobile(true);
  };

  useEffect(() => {
    // Transition Status Management
    if (!appLoaded) {
      setAppLoaded(true);
    }

    // Mobile Size Management
    toggleMobile();
    window.addEventListener("resize", toggleMobile);

    // Entry Fade Animation
    if (typeof window !== "undefined") {
      setTimeout(() => {
        enterAnimation();
      }, 500);
    }
  }, []);

  return (
    <>
      <Global
        styles={css`
          body {
            margin: 0;
            overflow: ${scrollLock ? "hidden !important" : "visible"};
            height: ${scrollLock ? "100vh" : "auto"};
            -webkit-overflow-scrolling: auto;
          }
          .tl-edges {
            margin: 0;
            overflow: ${scrollLock ? "hidden !important" : "visible"};
            height: ${scrollLock ? "100vh" : "auto"};
          }
          @font-face {
            font-family: "BlackChancery";
            src: url(${BlackChancery}) format("truetype");
            font-weight: normal;
            font-style: normal;
          }
        `}
      />
      <NavbarMain />
      <Div100vh
        className="white-transition-fade"
        css={css`
          z-index: 2000;
          position: fixed;
          background: #ffffff;
          top: 0;
          left: 0;
          height: 100vh;
          width: 100vw;
          opacity: 1;
        `}
      />
      {children}
    </>
  );
};

Layout.defaultProps = {
  scrollLock: false
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  scrollLock: PropTypes.bool
};

export default Layout;
