import gsap from "gsap";

export const exitAnimation = () => {
  gsap.to(".white-transition-fade", {
    duration: 0,
    css: { height: "100vh" }
  });
  gsap.to(".white-transition-fade", {
    duration: 0.5,
    css: { opacity: 1 }
  });
};

export const enterAnimation = () => {
  gsap.to(".white-transition-fade", {
    delay: 0.5,
    duration: 0.5,
    css: { opacity: 0 }
  });
  gsap.to(".white-transition-fade", {
    delay: 1,
    duration: 0,
    css: { height: 0 }
  });
};

export const slideDown = (
  duration,
  ease,
  currentSlideIndex,
  nextSlideIndex
) => {
  gsap.to("#slide-transition", {
    duration: 0,
    ease,
    css: { top: "100vh" }
  });
  gsap.to("#slide-transition", {
    duration: duration / 2,
    ease,
    css: { top: "0" }
  });
  gsap.to("#slide-transition", {
    delay: duration / 2,
    duration: duration / 2,
    ease,
    css: { top: "-100vh" }
  });

  // Current Slide Hide
  gsap.to(`.home-slide-${currentSlideIndex}`, {
    duration: duration / 2,
    ease,
    css: { top: "-100vh" }
  });

  // Next Slide is visible and is placed at top with 0 height
  gsap.to(`.home-slide-${nextSlideIndex}`, {
    duration: duration / 2,
    ease,
    css: { top: "0" }
  });
};

export const slideUp = (duration, ease, currentSlideIndex, nextSlideIndex) => {
  gsap.to("#slide-transition", {
    duration: 0,
    ease,
    css: { top: "-100vh" }
  });
  gsap.to("#slide-transition", {
    duration: duration / 2,
    ease,
    css: { top: 0 }
  });
  gsap.to("#slide-transition", {
    delay: duration / 2,
    duration: duration / 2,
    ease,
    css: { top: "100vh" }
  });

  // Current Slide Hide
  gsap.to(`.home-slide-${currentSlideIndex}`, {
    duration: duration / 2,
    ease,
    css: { top: "100vh" }
  });

  // Next Slide is visible and is placed at top with 0 height
  gsap.to(`.home-slide-${nextSlideIndex}`, {
    duration: duration / 2,
    ease,
    css: { top: "0" }
  });
};
